<template>
    <div>
        <div class="mobile-wine-list-wrap" v-if="membershipNFTList.length > 0">
            <div class="wine-list-content">
                <div class="wine-card-item-wrap"  v-for="membershipNFT in membershipNFTList" v-bind:key="membershipNFT.name">
                    <div class="wine-card-info-wrap" @click="moveMembershipNFT(membershipNFT.key)">
                        <!--<div class="wine-card-info-wrap">-->
                        <div class="wine-image-wrap" style="margin: 0 auto;">
                            <div class="wine-image" :style="{backgroundImage:`url(${membershipNFT.image}), url(${membershipNFT.fallbackImage})`}">

                            </div>
                        </div>
                        <div class="wine-content-wrap">
                            <div class="wine-text-info-wrap" style="margin: 0">
                                <p class="wine-title" style="height: auto;">{{membershipNFT.name}}</p>
                            </div>
                            <div class="wine-price-info">
                                 <!--<span class="title">Last</span>-->
                                <span class="price">{{$numberFormat(membershipNFT.price.coin)}} <span class="exchange-price">({{$numberFormat(membershipNFT.price.won).split('.')[0]}}원)</span></span>
                            </div>
                        </div>
                    </div>


                </div>
                <infinite-loading @infinite="nextPage">
                    <div slot="no-results">
                        <!-- 검색 결과 없을 때 -->
                        <!--<span>결과 없어용..!</span>-->
                    </div>
                    <div slot="no-more">
                        <!-- 검색 결과 더이상 없을 때 -->
                        <!--<span>더이상 결과 없어용..!</span>-->
                    </div>
                </infinite-loading>
            </div>
        </div>
        <div class="wine-list-no-result-wrap" v-if="membershipNFTList.length === 0">
            <div class="no-result">{{noResultText}}</div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "MobileMembershipList",
        components: {},
        props: {
            membershipNFTList: {},
            nextPage:{
                default: () => {},
                type:Function,
                required:true
            },
            noResultText: {
                type:String
            }
        },
        methods: {
            moveMembershipNFT(membershipNFTKey) {
                this.$router.push(`/market/membership/detail/${membershipNFTKey}`)
            }
        }
    }
</script>

<style scoped>

</style>
