<template>
  <div class="w-full">
    <div class="wine-list-wrap" v-if="membershipNFTList.length > 0">
      <div class="wine-list-content">
        <div class="wine-card-item-wrap" v-for="membershipNFT in membershipNFTList" v-bind:key="membershipNFT.name">
          <div class="wine-card-info-wrap" @click="moveMembershipNFT(membershipNFT.key)">
            <!--<div class="wine-card-info-wrap">-->
            <div class="wine-image-wrap" style="margin: 0 auto;">
              <div class="wine-image"
                   :style="{backgroundImage:`url(${membershipNFT.image}), url(${membershipNFT.fallbackImage})`}"></div>
            </div>
            <div class="wine-content-wrap">
              <div class="wine-text-info-wrap" style="margin: 0;">
                <p class="wine-title" style="height: auto;">{{ membershipNFT.name }}</p>
              </div>
              <div class="wine-price-info">
                <!--<span class="title">Last</span>-->
                <span class="price">{{ $numberFormat(membershipNFT.price.coin) }} <span
                    class="exchange-price">({{ $numberFormat(membershipNFT.price.won).split('.')[0] }}원)</span></span>
              </div>

            </div>
          </div>

        </div>

      </div>
      <div class="wine-list-pager">
        <WinexPagination
            :current-page="pageInfo.current_page"
            :total-page="pageInfo.total_page"
            :move-page="movePage"></WinexPagination>
      </div>
    </div>
    <div class="pt-[160px] flex flex-col items-center w-full justify-center gap-[20px]"
         v-if="membershipNFTList.length === 0">
      <img src="../../assets/images/empty.png" class="w-[90px] h-[90px]"/>
      <p class="text-[24px] text-lightDark font-[Suit-Medium]">NFT가 없습니다</p>
    </div>
  </div>
</template>

<script>
import WinexPagination from "../paginator/WinexPagination";

export default {
  name: "PcMembershipList",
  components: {WinexPagination},
  props: {
    membershipNFTList: {},
    pageInfo: {required: true},
    movePage: {
      default: () => {
      },
      type: Function,
      required: true
    },
    noResultText: {
      type: String,
      default: "NFT 리스트를 로드 중입니다. 잠시만 기다려 주십시오."
    }
  },
  methods: {
    // TODO ::  멤버쉽 상세 퍼블 페이지로 이동
    moveMembershipNFT(membershipNFTKey) {
      this.$router.push(`/market/membership/detail/${membershipNFTKey}`)
    }
  }
}
</script>

<style scoped>

</style>
