<template>

  <div class="pb-[100px]">
    <div class="flex gap-[80px] mobile:!hidden pt-[20px]">

      <div class="filter-wrap  w-[200px]">
        <div class="flex justify-content-between py-[18px] items-center justify-center">
          <h2 class="text-[24px] font-[Suit-Bold] leading-[44px]">Filter</h2>
          <div class="cursor-pointer w-[20px] h-[20px] bg-no-repeat bg-center" @click="resetFilter" :style="{backgroundImage: `url('${require('@/assets/images/icon/filter-reload.png')}')`}"/>
        </div>

        <div class="h-[1px] w-full bg-light" />

        <PcGroupCheckbox
            :list="statusList"
            title="Status"
            :is-open="isOpenStatusFilter"
            :change-filter="changStatus"
        />

      </div>

      <div class="flex flex-col gap-[40px] items-end w-full min-h-[500px]">
        <ul class="flex">
          <li class="leading-[40px] px-[12px] text-[16px] cursor-pointer" :style="{color: sortFilter === 1 ? '#A8221A' : '#424242', fontFamily: sortFilter === 1 ? 'Suit-Bold' : 'Suit-Regular'}" @click="changeOrder(1)">최신순</li>
          <li class="leading-[40px] px-[12px] text-[16px] cursor-pointer" :style="{color: sortFilter === 2 ? '#A8221A' : '#424242', fontFamily: sortFilter === 2 ? 'Suit-Bold' : 'Suit-Regular'}" @click="changeOrder(2)">가격 높은 순</li>
          <li class="leading-[40px] px-[12px] text-[16px] cursor-pointer" :style="{color: sortFilter === 3 ? '#A8221A' : '#424242', fontFamily: sortFilter === 3 ? 'Suit-Bold' : 'Suit-Regular'}" @click="changeOrder(3)">가격 낮은 순</li>
        </ul>


        <div class="pc-wine-list w-full">
          <PcMembershipList :membership-n-f-t-list="viewMembershipList" :move-page="clickPager"
                          :page-info="pageInfo" :no-result-text="noResultText"></PcMembershipList>
        </div>
      </div>

    </div>
    <div class="hidden mobile:!flex flex-col pt-[48px] px-[16px] gap-[20px]">

      <div class="flex flex-col gap-[16px]">
        <div class="flex justify-between">
          <div class="bg-[center_left] pl-[32px] bg-no-repeat bg-[length_24px] text-[16px] !font-[Suit-Bold] text-darkDark leading-[32px]"
               :style="{backgroundImage: `url('${require('@/assets/images/icon/mobile-filter.png')}')`}"
               @click="filterModalIsOpen = true"
          >Filter</div>
          <div class="py-[6px] text-[16px] text-[#A8221A] font-[Suit-Bold]" @click="isOpenSortFilter=true">{{ getSort() }}</div>
        </div>

        <div class="w-full h-[1px] bg-lightLightDark" />

        <div v-if="selectFilter.length > 0 " class="flex gap-[10px] relative">
          <div class="flex w-full overflow-auto gap-[8px] flex-grow pr-[30px]">
            <div v-for="(item, index) in selectFilter" :key="index"
                 class="flex w-fit flex-shrink-0 items-center flex-[0 0 auto] border border-lightLightDark rounded-[16px] h-[32px] pl-[16px] pr-[6px]"
            >
              <p class=" text-[14px] font-[Suit-Medium] text-darkLight m-0 flex-shrink-0">{{item.name}}</p>
              <img src="@/assets/images/icon/icon-dark-dark-close.png" class="w-[32px] h-[32px] flex-shrink-0" />
            </div>
          </div>
          <div class="cursor-pointer w-[32px] h-[32px] bg-no-repeat bg-center" @click="resetFilter" :style="{backgroundImage: `url('${require('@/assets/images/icon/filter-reload.png')}')`}"/>
          <div class="w-[40px] h-[32px] top-0 bottom-0 bg-gradient-to-r from-[#FFFFFF00] to-white absolute right-[39px]" />
        </div>
      </div>


      <mobile-membership-list
          :membership-n-f-t-list="viewMembershipList"
          :next-page="nextPage"></mobile-membership-list>
    </div>


    <FilterModal
        v-if="filterModalIsOpen"
        @close="filterModalIsOpen=false"
        @change="mobileFilterChange"
        :is-mypage="true"
        :status="statusList"
    >
    </FilterModal>


    <NftSortModal :order="sortFilter" v-if="isOpenSortFilter" @change="changeOrder" />
  </div>
</template>
<script>

import PcMembershipList from "../../../components/list/PcMembershipList";
import MobileMembershipList from "../../../components/list/MobileMembershipList";
import getContractApi from "../../../api/contract";
import PcGroupCheckbox from "@/components/checkbox/pcGroupCheckbox";
import FilterModal from "@/components/modals/FilterModal";
import NftSortModal from "@/components/modals/NftSortModal";

export default {
  components: {NftSortModal, PcGroupCheckbox, MobileMembershipList, PcMembershipList, FilterModal},
  created() {
    this.market = getContractApi()
  },
  data() {
    return {
      account: null,
      contract: null,
      market: null,
      viewMembershipList: [],
      noResultText: "NFT 리스트를 로드 중입니다. 잠시만 기다려 주십시오.",
      filteredMembershipList: [],
      pageInfo: {
        current_page: 1,
        total_page: 1,
        item_per_page: 1
      },
      sellIng: false,
      sortFilter: this.$route.query.order ? Number(this.$route.query.order) : 1 ,
      state: "01",
      searchData: {},


      isOpenStatusFilter:true,
      statusList: [
        {
          key: '01',
          name: 'Listed',
          select: false,
        },
        {
          key: '02',
          name: 'Unlisted',
          select: false,
        },
      ],

      filterModalIsOpen: false,
      isOpenSortFilter: false,
      selectFilter: []
    }
  },
  mounted() {
    let self = this
    this.searchData = this.$route.query
    if (this.searchData.mypage) {
      this.state = this.searchData.mypage
    }


    if(this.$route.query.mypage){
      this.$route.query.mypage.split(',').forEach((item) => {
        this.statusList.forEach((status) => {
          if(item === status.key){
            status.select = true;
          }
          this.selectFilter.push(status)
        })
      })
    }

    this.market.getMembershipMarket().getUserAddress().then(data => {
      if (data === undefined) {
        alert('메타마스크 연결을 확인해 주세요.')
        location.href = '/'
      } else {
        self.account = data
        self.searchData.owner = self.account
        if (self.searchData.page) {
          self.getMembershipList(self.searchData.page)
        } else {
          self.getMembershipList(1)
        }
      }
    })

  },
  methods: {

    getSort(){
      // console.dir(this.sortFilter)

      switch(this.sortFilter){
        case 1:
          return "최신순";
        case 2:
          return "가격 높은 순";
        case 3:
          return "가격 낮은 순";

      }
    },
    resetFilter() {
      this.statusList.forEach((status) => {
        status.select = false;
      })

      this.getMembershipList(1, 'pass');
    },
    changStatus(){
      this.getMembershipList(1, 'pass')
    },
    mobileFilterChange(data){
      this.filterModalIsOpen = false;
      this.statusList = data.status;

      this.getMembershipList(1, 'pass')
    },
    changeOrder(order){
      this.sortFilter = order;
      this.filterModalIsOpen=false;
      this.getMembershipList(1, 'pass')
    },
    /*changeFilter(filterType) {
        // 통신 처리
        this.sortFilter = filterType;
    },*/

    getMembershipList(page, mode, state) {
      // 통신 처리
      /*if(page !== this.pageInfo.current_page) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }*/

      let self = this
      this.searchData.page = page
      // this.searchData.mypage = this.state
      if (mode === "pass") {


        const query = {
          mypage: this.statusList.map((status) => status.select ? status.key : null).filter((item) => item != null).join(','),
          page: page,
          order: this.sortFilter
        }

        this.$router.replace({
          path: this.$route.path,
          query: query
        }).catch((err) => {
          console.dir(err)
          self.$router.go(0)
        })

        // location.href = '/users/membership/nft?' + new URLSearchParams(this.searchData).toString()
      }

      this.$store.dispatch('membershipMarket/getMembershipList', this.searchData).then((data) => {
        if (data.total === 0) {
          self.noResultText = "검색 결과가 없습니다."
        }
        let searchedMembershipList = data.data
        for (let i = 0; i < searchedMembershipList.length; i++) {
          searchedMembershipList[i].image = searchedMembershipList[i].membership_image
          searchedMembershipList[i].key = searchedMembershipList[i].market_key + "/" + searchedMembershipList[i].nft_key
          searchedMembershipList[i]['price'] = {
            coin: searchedMembershipList[i].last_price / 1000000,
            won: self.market.toWon(searchedMembershipList[i].last_price / 1000000)
          }
        }
        if (mode === 'mobile') {
          self.viewMembershipList.push(...searchedMembershipList)
          state.loaded();
        } else {
          self.viewMembershipList = searchedMembershipList
        }

        self.pageInfo = {
          current_page: data.current_page,
          total_page: data.last_page,
          item_per_page: data.per_page,
        }

        return data;
      }).catch(e => {
        return e;
      })


    },
    clickPager(page) {
      this.getMembershipList(page, "pass")
    },

    nextPage($state) {
      // 통신 연동 부분
      if (this.pageInfo.current_page < this.pageInfo.total_page) {
        this.getMembershipList(this.pageInfo.current_page + 1, 'mobile', $state)
      } else {
        $state.complete(); // 다음 페이지가 없을 경우
      }
    },

  },

}
</script>

<style scoped lang="scss">
@import "@/assets/scss/mypage.scss";
</style>
